<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">CMS Specialist
                                <router-link to="/careers/cms-specialist/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for an experienced Content Management System Specialist to join our growing team. CMS Specialist will be responsible for creating, improving and maintaining content to achieve partner's business goals. Should also maintain a comprehensive knowledge base of SEO development best practices and guidelines. A successful content management specialist needs strong organizational skills and an excellent understanding of CMS systems.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Modify customer-facing and backend content for live web pages.</li>
                                            <li><i class="icon-ok"></i>Will work together with a Web Implementations Manager and Customer Success Manager to implement changes on the partner's website.</li>
                                            <li><i class="icon-ok"></i>Developing websites using HTML, CSS, and sometimes XML is needed. </li>
                                            <li><i class="icon-ok"></i>Knowledgeable with different CMS platforms and basic to advance knowledge in SEO.</li>
                                            <li><i class="icon-ok"></i>Maintaining a comprehensive knowledge base of SEO development best practices and guides is part of your responsibilities.</li>
                                            <li><i class="icon-ok"></i>Should also maintain a comprehensive knowledge base of SEO development best practices and guidelines.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Qualifications
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Have experience in Search Engine Optimization (SEO)</li>
                                            <li><i class="icon-ok"></i>Proficient or have experience using Hubspot, Shopify, Squarespace, Magento, Webflow, WordPress, Joomla, and Drupal preferred</li>
                                            <li><i class="icon-ok"></i>Detailed understanding of Content Management Systems and experience implementing content management infrastructures</li>
                                            <li><i class="icon-ok"></i>Familiarity with other digital marketing technologies such as social media, search, web analytics, e-commerce, and behavioral targeting</li>
                                            <li><i class="icon-ok"></i>Excellent understanding of emerging technologies and the ability to define and articulate various strategic digital technologies to our partners</li>
                                            <li><i class="icon-ok"></i>Provide digital media industry and solution expertise to apply eFLEX's value proposition to partner business requirements and develop solution architectures to address common digital media business challenges</li>
                                            <li><i class="icon-ok"></i>Excellent communication skills</li>
                                            <li><i class="icon-ok"></i>Excellent partner relationship skills, ability to develop relationships with senior partner executives based on credibility in the digital marketing area. Must be able to effectively manage partner expectations</li>
                                            <li><i class="icon-ok"></i>Ability to multi-task and support multiple partner projects at the same time</li>
                                            <li><i class="icon-ok"></i>Basic to advanced experience in programming is a plus.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/cms-specialist/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    }
}
</script>